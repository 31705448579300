import React from 'react'

import { CardProps } from '../../../types/card'

import validateUrl from '../../utils/url-validator'

import { Card, Form, Input, Tooltip } from 'antd'
import { LinkOutlined, CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons'

import { ReelData } from '../../../types/reel'
import { useFetchReel } from '../../hooks/fetch-reel-data'

import { ReuseVideosModal } from './reuse-video-modal'
import { Poster } from './poster'
import { SecondaryButton } from '../ui/secondary-button'
import { VideoMetadata } from '../../../../types/videos'

const VALID_IG_LINK = /https:\/\/www\.instagram.com\/reel\/\S{9,}\/?/

export const CardContent: React.FC<CardProps> = React.memo(
  ({ input, required, handleInputChange, isSuccess }) => {
    const { fetchReelData } = useFetchReel()
    const [reelState, setReelState] = React.useState<ReelData>({
      title: '',
      previewUrl: '',
    })
    const isLoaded = !!input.url
    const [isModalVisible, setModalVisible] = React.useState(false)

    // hace fetch del reel cuando hay una url válida
    React.useEffect(() => {
      if (input.url && input.isValid && !isModalVisible) {
        fetchReelData(input.url).then(data => {
          if (data) {
            setReelState(data)

            if (!input.titleUpdated) {
              handleInputChange(input.id, 'title', data.title)
            }
          }
        })
      }
    }, [input.url, input.isValid, input.id, input.titleUpdated, handleInputChange, fetchReelData, isModalVisible])

    // limpia el campo CTA si coincide con la URL cuando se enfoca
    const handleFocusCTA = React.useCallback(() => {
      if (input.cta === input.url) {
        handleInputChange(input.id, 'cta', '')
      }
    }, [input.cta, input.url, handleInputChange, input.id])

    // setea el campo CTA a la URL si está vacío cuando se desenfoca
    const handleBlurCTA = React.useCallback(() => {
      if (input.cta.trim() === '') {
        handleInputChange(input.id, 'cta', input.url, true)
      }
    }, [input.cta, input.url, handleInputChange, input.id])

    // setea el campo título al título por defecto del reel si está vacío cuando se desenfoca
    const handleBlurTitle = React.useCallback(() => {
      if (input.title.trim() === '') {
        handleInputChange(input.id, 'title', reelState.title)
      }
    }, [input.title, reelState.title, handleInputChange, input.id])

    const handleReuseVideo = (video: VideoMetadata) => {
      handleInputChange(input.id, 'url', video.originalUri || video.videoUrl || '') // Setea videoUrl en el campo URL
      handleInputChange(input.id, 'cta', video.cta || '') // Setea videoUrl en el campo CTA
      handleInputChange(input.id, 'title', video.caption || '') // Setea caption en el campo Título
      handleInputChange(input.id, 'posterUrl', video.posterUrl || '') // Setea caption en el campo PosterUrl

      // Usa posterUrl como preview para videos seleccionados desde el modal
      setReelState(prevState => ({
        ...prevState,
        previewUrl: video.posterUrl, // Actualiza la preview con posterUrl del mock
      }))

      setModalVisible(false) // Cierra el modal
    }

    React.useEffect(() => {
      if (!input.url) {
        // Limpia la preview cuando no hay URL
        setReelState(prevState => ({ ...prevState, previewUrl: '' }))
      } else if (input.url.includes('instagram')) {
        // Limpia la preview del mock si es una URL de Instagram
        setReelState(prevState => ({ ...prevState, previewUrl: '' }))
      }
    }, [input.url])

    // Fetches the reel data when the URL is valid and the input is valid
    React.useEffect(() => {
      if (input.url && input.isValid && VALID_IG_LINK.test(input.url)) {
        fetchReelData(input.url)
      }
    }, [input.url, input.isValid, fetchReelData])

    return (
      <>
        <Card
          key={input.id}
          style={{
            marginBottom: '15px',
            padding: '0px 8px',
            height: 'auto',
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'flex-start', // Alineación de los elementos al inicio
              paddingTop: '0px',
            }}
          >
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {/* Botón "Reutilizar videos publicados" */}
              <SecondaryButton
                icon={<ClockCircleOutlined />} // Ícono azul
                onClick={() => setModalVisible(true)}
                style={{
                  padding: '0 8px',
                  height: '25px', // Altura estándar
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '50%', // Botón tipo "block"
                  left: '203px',
                }}
              >
                Reutilizar videos publicados
              </SecondaryButton>
              <Form.Item
                name={`link${input.id}`}
                rules={
                  !required
                    ? [
                        {
                          validator: (_, value) =>
                            value && !validateUrl(value)
                              ? Promise.reject(new Error('Debe ser una URL válida.'))
                              : Promise.resolve(),
                        },
                      ]
                    : [
                        { required: true, message: 'Por favor, ingresa una URL valida.' },
                        {
                          validator: (_, value) =>
                            value && !validateUrl(value)
                              ? Promise.reject(new Error('Ingresar una URL valida.'))
                              : Promise.resolve(),
                        },
                      ]
                }
                style={{ margin: 0 }}
              >
                <Input
                  value={input.url}
                  onChange={e => handleInputChange(input.id, 'url', e.target.value)}
                  prefix={<LinkOutlined />}
                  placeholder='Pega tu URL aquí'
                  style={{ color: isLoaded ? '#A7A7A7' : 'inherit' }}
                />
              </Form.Item>

              <Form.Item
                name={`cta${input.id}`}
                rules={
                  !required
                    ? [
                        {
                          validator: (_, value) =>
                            value && !validateUrl(value)
                              ? Promise.reject(new Error('El CTA debe ser una URL válida.'))
                              : Promise.resolve(),
                        },
                      ]
                    : [
                        { required: true, message: 'Por favor, ingresa un CTA válido.' },
                        {
                          validator: (_, value) =>
                            value && !validateUrl(value)
                              ? Promise.reject(new Error('El CTA debe ser una URL válida.'))
                              : Promise.resolve(),
                        },
                      ]
                }
                style={{ margin: '0px' }}
              >
                <Input
                  value={input.cta}
                  onChange={e => handleInputChange(input.id, 'cta', e.target.value)}
                  onFocus={handleFocusCTA}
                  onBlur={handleBlurCTA}
                  placeholder='Ingresa el enlace del CTA'
                  style={{ color: isLoaded ? '#A7A7A7' : 'inherit' }}
                />
              </Form.Item>

              <Form.Item
                name={`title${input.id}`}
                rules={[
                  {
                    required: required,
                    message: 'Ingresar un título válido',
                  },
                ]}
                style={{ margin: 0 }}
              >
                <Input
                  value={input.title}
                  onChange={e => handleInputChange(input.id, 'title', e.target.value)}
                  onBlur={handleBlurTitle}
                  placeholder='Título del reel'
                  style={{ color: isLoaded ? '#A7A7A7' : 'inherit' }}
                />
              </Form.Item>
            </div>

            {/* Vista unificada con el nuevo componente Poster */}
            {input.url?.includes('instagram') ? (
              <Poster url={reelState.previewUrl} preview={!!reelState.previewUrl} />
            ) : (
              <Poster url={input.posterUrl} preview={true} />
            )}

            {/* Ícono de validación a la derecha de InstagramPreview */}
            {isSuccess && (
              <Tooltip title='Contenido guardado'>
                <CheckCircleOutlined
                  style={{
                    color: '#119400',
                    marginLeft: '8px',
                    fontSize: '16px',
                    position: 'absolute',
                    right: '-24px',
                  }}
                />
              </Tooltip>
            )}
          </div>
        </Card>

        <ReuseVideosModal
          visible={isModalVisible}
          onClose={() => setModalVisible(false)}
          onSelect={handleReuseVideo} // Llama a la función de manejo
        />
      </>
    )
  },
  (prevProps, nextProps) => {
    // compara solo los campos que afectan el renderizado
    const prevInput = prevProps.input
    const nextInput = nextProps.input

    return (
      prevInput.url === nextInput.url &&
      prevInput.id === nextInput.id &&
      prevInput.order === nextInput.order &&
      prevInput.cta === nextInput.cta &&
      prevInput.title === nextInput.title &&
      prevProps.isSuccess === nextProps.isSuccess
    )
  },
)
