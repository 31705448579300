import React from 'react'
import { ConfigProvider } from 'antd'
import { motion, AnimatePresence } from 'framer-motion'

import VideoImpression from './video-impression'
import SuccessfulViews from './successful-views'
import VideoClicks from './video-clicks'
import VideoCard from '../video-card'

import { CARD_CONFIG } from '../../../theme/card'
import { AnalyticsData, VideoWithAnalytics } from '../../../../../types/videos'

const MetricsContent: React.FC<{ data: AnalyticsData | VideoWithAnalytics }> = ({ data }) => {
  const config = 'posterUrl' in data ? CARD_CONFIG.standard : CARD_CONFIG.performance

  let containerStyles = {
    backgroundColor: '#ffffff',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '0',
    paddingRight: '0',
    marginBottom: '0',
    borderRadius: '16px',
    gap: '15px',
    height: '145px',
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  }

  if ('posterUrl' in data) {
    containerStyles = {
      ...containerStyles,
      backgroundColor: '#F6F7F9',
      paddingLeft: '10px',
      paddingRight: '10px',
      marginBottom: '13px',
      height: 'auto',
    }
  }

  return (
    <ConfigProvider>
      <AnimatePresence mode='sync'>
        <motion.div
          layout
          key={`metric-${data.video_source}`}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          <div style={containerStyles}>
            {'posterUrl' in data && (
              <VideoCard
                videoData={{
                  videoUrl: data.videoUrl,
                  previewUrl: data.previewUrl,
                  posterUrl: data.posterUrl,
                  caption: data.caption,
                  startDate: data.startDate,
                }}
                size={{ width: 108, height: 108 }}
              />
            )}
            <VideoImpression impressions={data.impressions} config={config} />
            <SuccessfulViews impressions={data.impressions} video_views={data.video_views} config={config} />
            <VideoClicks video_views={data.video_views} cta_click={data.cta_click} config={config} />
          </div>
        </motion.div>
      </AnimatePresence>
    </ConfigProvider>
  )
}

export default MetricsContent
