import React from 'react'
import { Card, Col, Row, Skeleton, Tooltip, Typography } from 'antd'
import { Eye } from 'lucide-react'

import { ConfigType } from '../../../theme/card'
import { getMetricTooltipContent, numberFormatter } from '../../../utils/metrics-utils'
import { useDashboardContext } from '../../../context/dashboard'

const { Text } = Typography
interface VideoImpressionProps {
  impressions: number
  config: ConfigType
}

const VideoImpression: React.FC<VideoImpressionProps> = ({ impressions, config }) => {
  const { isLoading } = useDashboardContext()

  return (
    <Tooltip
      title={getMetricTooltipContent({ metricType: 'videoImpressions', value: impressions })}
      placement='topLeft'
    >
      <Card
        style={{
          ...config.size,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          padding: '7px',
        }}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <Row
          style={{
            width: '100%',
            height: config.rowHeight,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Eye style={{ width: '14px' }} />
        </Row>

        <Row
          style={{
            width: '100%',
            height: '55px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {isLoading ? (
            <Skeleton.Input active={true} />
          ) : (
            <Col style={{ display: 'flex', justifyContent: 'center' }}>
              <Text style={{ fontSize: config.fontSize.paragraph, fontWeight: 600 }}>
                {numberFormatter(impressions)}
              </Text>
            </Col>
          )}
        </Row>

        <Row
          style={{
            width: '100%',
            height: config.rowHeight,
            flexGrow: 1,
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Text style={{ fontSize: config.fontSize.title }}>{config.title.videoImpressions}</Text>
        </Row>
      </Card>
    </Tooltip>
  )
}

export default VideoImpression
