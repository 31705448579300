import React from 'react'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { Button, Flex, FloatButton, Form, DatePicker, Row, Col, Modal, Typography, message, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Calendar, Plus, Save, RotateCcw } from 'lucide-react'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core'

import { arrayMove, sortableKeyboardCoordinates, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'

import { InputState } from '../../types/form'

import { useFetchCarouselDates } from '../hooks/carousel/useFetchCarouselDates'
import { useFetchCarouselData } from '../hooks/carousel/useFetchCarouselData'
import { useSaveCarouselContent } from '../hooks/carousel/useSaveCarouselContent'
import { SortableCard } from '../components/content/sortable-card'
import { HelpModal } from '../components/content/help-modal'

import { SecondaryButton } from '../components/ui/secondary-button'

import { MAX_CARDS } from '../constants'
import { tokens } from '../theme/tokens'

import validateUrl from '../utils/url-validator'
import formatDate from '../utils/date-formatter'
import sanitizeUrl from '../utils/url-sanitizer'

const today = dayjs()

const INITIAL_REQUIRED_CARDS = 4

const submittedDateStyle = {
  color: '#FFFFFF',
  backgroundColor: tokens.colors.supporting.green[500],
  borderRadius: '50%',
  width: '25px',
  height: '25px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '7px',
  fontWeight: 'bold',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
}

interface SaveState {
  isSaving: boolean
  savedCards: Record<string, boolean>
  lastSavedDate: string | null
}

const initialInputState: InputState = {
  id: '',
  order: 0,
  url: '',
  cta: '',
  title: '',
  posterUrl: '',
  showPlaceholder: true,
  isValid: false,
  ctaUpdated: false,
  titleUpdated: false,
}

const generateInitialInputs = (length: number): InputState[] =>
  Array.from({ length }, (_, index) => ({
    ...initialInputState,
    id: uuidv4(),
    order: index + 1,
    posterUrl: '', 
  }))

export const EditFormPage: React.FC<{ widgetId: string }> = ({ widgetId }) => {
  const [form] = Form.useForm()
  const date = Form.useWatch('date', form)
  const currentDate = formatDate(date, today)

  // API hooks
  const { fetchSubmittedDates, submittedDates } = useFetchCarouselDates({ widgetId })
  const { fetchCarouselData } = useFetchCarouselData({ widgetId })
  const { saveCarouselContent } = useSaveCarouselContent({ widgetId })
  const [inputs, setInputs] = React.useState<InputState[]>(() => generateInitialInputs(INITIAL_REQUIRED_CARDS))
  const [isFormComplete, setIsFormComplete] = React.useState(false)
  const [isModified, setIsModified] = React.useState(false)
  const [isHelpModalOpen, setHelpModalOpen] = React.useState<boolean>(false)
  const [initialInputs, setInitialInputs] = React.useState<InputState[]>([])
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [hasDuplicateUrl, setHasDuplicateUrl] = React.useState(false)
  const [saveState, setSaveState] = React.useState<SaveState>({
    isSaving: false,
    savedCards: {},
    lastSavedDate: null,
  })

  const checkFormCompletion = React.useCallback(() => {
    const allFieldsComplete = inputs.every(
      input => input.url.trim() !== '' && input.cta.trim() !== '' && input.title.trim() !== '',
    )
    setIsFormComplete(allFieldsComplete && !!date)
  }, [inputs, date])

  const showResetConfirmation = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleAddCard = () => {
    const newCardId = uuidv4()
    setInputs(prev => [
      ...prev,
      {
        ...initialInputState,
        id: newCardId,
        order: prev.length + 1,
      },
    ])
    setIsModified(true)
  }

  const handleDeleteCard = (id: string) => {
    setInputs(prev => {
      const newInputs = prev.filter(input => input.id !== id)

      if (newInputs.length < INITIAL_REQUIRED_CARDS) {
        // Asegurarse de mantener el mínimo requerido de tarjetas
        const newCard = {
          ...initialInputState,
          id: uuidv4(),
          order: newInputs.length + 1,
        }
        newInputs.push(newCard)
      }

      const updatedInputs = newInputs.map((input, index) => ({
        ...input,
        order: index + 1,
      }))

      // Verificar duplicados después de actualizar las tarjetas
      checkForDuplicateUrls(updatedInputs)

      return updatedInputs
    })

    setIsModified(isFormChanged())
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      setInputs(inputs => {
        const oldIndex = inputs.findIndex(input => input.id === active.id)
        const newIndex = inputs.findIndex(input => input.id === over?.id)
        const reorderedInputs = arrayMove(inputs, oldIndex, newIndex)

        setIsModified(isFormChanged())
        return reorderedInputs.map((input, index) => ({
          ...input,
          order: index + 1,
        }))
      })
    }
  }

  const fetchCarouselContentByDate = React.useCallback(
    async (selectedDate: dayjs.Dayjs) => {
      try {
        const content = await fetchCarouselData(selectedDate)
        const formattedDate = formatDate(selectedDate)
        const formValues: Record<string, string> = {}

        const updatedInputs = content.map(carouselItem => {
          const isInstagramReel = carouselItem.link?.includes('instagram.com/reel')
          const input = {
            id: carouselItem.id,
            order: carouselItem.order,
            url: carouselItem.link || '',
            cta: carouselItem.cta || '',
            title: carouselItem.title || '',
            posterUrl: isInstagramReel ? '' : carouselItem.posterUrl || '',
            showPlaceholder: !carouselItem.link,
            isValid: !!carouselItem.link,
            ctaUpdated: !!carouselItem.cta,
            titleUpdated: !!carouselItem.title,
          }

          formValues[`link${input.id}`] = input.url
          formValues[`cta${input.id}`] = input.cta
          formValues[`title${input.id}`] = input.title

          return input
        })

        if (updatedInputs.length === 0) {
          updatedInputs.push(...generateInitialInputs(INITIAL_REQUIRED_CARDS))
        }

        setInputs(updatedInputs)
        setInitialInputs(updatedInputs)

        setSaveState(prev => ({
          ...prev,
          lastSavedDate: formattedDate,
          savedCards: updatedInputs.reduce(
            (acc, input) => ({
              ...acc,
              [input.id]: input.isValid,
            }),
            {},
          ),
        }))

        form.setFieldsValue(formValues)
        setIsModified(false)
      } catch (err) {
        if (err instanceof Error) {
          const errorMessage = err.message || ''
          if (errorMessage.includes('404')) {
            const emptyInputs = generateInitialInputs(MAX_CARDS)
            setInputs(emptyInputs)
            setInitialInputs(emptyInputs)
            setIsModified(false)
          }
        } else {
          console.error('An unexpected error occurred:', err)
        }
      }
    },
    [form, fetchCarouselData],
  )

  const isFormChanged = React.useCallback(() => {
    if (inputs.length !== initialInputs.length) return true

    return inputs.some((input, index) => {
      const initialInput = initialInputs[index]
      return (
        input.order !== initialInput.order ||
        input.url.trim() !== initialInput.url.trim() ||
        input.cta.trim() !== initialInput.cta.trim() ||
        input.title.trim() !== initialInput.title.trim()
      )
    })
  }, [inputs, initialInputs])

  const handleSubmit = async (values: any) => {
    const date = formatDate(values.date)

    setSaveState(prev => ({ ...prev, isSaving: true }))

    try {
      const payload = {
        content: inputs.map(input => ({
          id: input.id,
          order: input.order,
          link: sanitizeUrl(values[`link${input.id}`]),
          cta: sanitizeUrl(values[`cta${input.id}`]),
          title: values[`title${input.id}`],
          posterUrl: input.url.includes('instagram.com/reel') ? '' : input.posterUrl || '',
        })),
      }

      const success = await saveCarouselContent(date, payload)

      if (success) {
        setSaveState({
          isSaving: false,
          savedCards: inputs.reduce(
            (acc, input) => ({
              ...acc,
              [input.id]: true,
            }),
            {},
          ),
          lastSavedDate: date,
        })

        setIsModified(false)
        setInitialInputs(inputs)
        message.success('El contenido se ha enviado exitosamente')
        fetchSubmittedDates()
      }
    } catch (error) {
      setSaveState(prev => ({ ...prev, isSaving: false }))
      message.error('Hubo un error al enviar el contenido')
    }
  }

  const checkForDuplicateUrls = (updatedInputs: InputState[]) => {
    const urls = updatedInputs.map(input => input.url.trim()).filter(Boolean)
    const duplicateUrls = urls.filter((url, index, self) => self.indexOf(url) !== index)

    // Actualizar los errores de los campos en el formulario
    updatedInputs.forEach(input => {
      const isDuplicate = duplicateUrls.includes(input.url.trim())
      form.setFields([
        {
          name: `link${input.id}`,
          errors: isDuplicate ? ['La URL ya está en uso en otra tarjeta.'] : [],
          validating: false,
        },
      ])
    })

    // Actualizar el estado de duplicados
    setHasDuplicateUrl(duplicateUrls.length > 0)
  }

  const handleInputChange = (
    id: string,
    field: 'url' | 'cta' | 'title' | 'posterUrl',
    value: string,
    isRestoringDefault: boolean = false,
  ) => {
    setInputs(prevInputs => {
      const updatedInputs = prevInputs.map(input => {
        if (input.id === id) {
          // solo marca como modificado si no está restaurando al valor por defecto
          if (!isRestoringDefault && input[field] !== value) {
            setIsModified(true)

            setSaveState(prev => ({
              ...prev,
              savedCards: {
                ...prev.savedCards,
                [id]: false,
              },
            }))
          } else if (isRestoringDefault) {
            setSaveState(prev => ({
              ...prev,
              savedCards: {
                ...prev.savedCards,
                [id]: true,
              },
            }))
          }

          const updates: Partial<InputState> = { [field]: value }

          if (field === 'url') {
            updates.showPlaceholder = !value
            updates.isValid = validateUrl(value)

            if (!value) {
              // si la url está vacía, también limpiamos el título y el cta
              updates.title = ''
              updates.cta = ''
              updates.titleUpdated = false // reset titleUpdated flag
              updates.posterUrl = '' 
            } else {
              updates.cta = value
            }
          } else if (field === 'cta') {
            updates.cta = value
            updates.isValid = validateUrl(value)
            updates.ctaUpdated = !isRestoringDefault
          } else if (field === 'title') {
            updates.title = value.replace(/\n+/g, ' ')
            updates.titleUpdated = value.trim() !== '' || input.titleUpdated
          }

          if (field === 'url' && !value.includes('instagram.com/reel')) {
            updates.posterUrl = input.posterUrl || '' // Mantener el poster si ya está presente
          }

          return { ...input, ...updates }
        }
        return input
      })
      checkForDuplicateUrls(updatedInputs)
      setIsModified(true)

      updatedInputs.forEach(input => {
        if (updatedInputs.filter(i => i.url === input.url && i.url).length > 1) {
          form.setFields([
            {
              name: `link${input.id}`,
              errors: ['La URL ya está en uso en otra tarjeta.'],
              validating: false,
            },
          ])
        } else {
          form.setFields([{ name: `link${input.id}`, errors: [] }])
        }
      })

      return updatedInputs
    })
  }

  const isSubmittedDate = (currentDate: dayjs.Dayjs): boolean => {
    if (!currentDate) return false
    return submittedDates.includes(formatDate(currentDate))
  }

  const handleConfirmReset = () => {
    const resetValues = initialInputs.reduce(
      (acc, input) => {
        acc[`link${input.id}`] = input.url
        acc[`cta${input.id}`] = input.cta
        acc[`title${input.id}`] = input.title
        return acc
      },
      {} as Record<string, string>,
    )

    form.setFieldsValue(resetValues)
    setInputs(initialInputs)
    setIsModified(false)
    setIsModalVisible(false)
    setHasDuplicateUrl(false)

    form.setFields(
      initialInputs.map(input => ({
        name: `link${input.id}`,
        errors: [],
      })),
    )

    // verificar si había un guardado previo para esta fecha
    const wasDatePreviouslySaved =
      saveState.lastSavedDate === currentDate &&
      Object.values(saveState.savedCards).some(cardSaved => cardSaved === true)

    if (wasDatePreviouslySaved) {
      // restaurar el estado de guardado para todos los inputs iniciales
      setSaveState(prev => ({
        ...prev,
        savedCards: initialInputs.reduce(
          (acc, input) => ({
            ...acc,
            [input.id]: true,
          }),
          {},
        ),
      }))
    } else {
      // si nunca se envió, asegurarse de limpiar cualquier ícono de éxito
      setSaveState(prev => ({
        ...prev,
        savedCards: {},
        lastSavedDate: null,
      }))
    }
  }

  React.useEffect(() => {
    const formValues: Record<string, string> = {}
    inputs.forEach(input => {
      formValues[`link${input.id}`] = input.url
      formValues[`cta${input.id}`] = input.cta
      formValues[`title${input.id}`] = input.title
    })
    form.setFieldsValue(formValues)
  }, [inputs, form])

  React.useEffect(() => {
    if (date) {
      fetchCarouselContentByDate(date)
    }
  }, [date, fetchCarouselContentByDate])

  React.useEffect(() => {
    if (widgetId) {
      fetchSubmittedDates()
    }
  }, [widgetId, fetchSubmittedDates])

  React.useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisitedEditForm')
    if (!hasVisited) {
      setHelpModalOpen(true)
      localStorage.setItem('hasVisitedEditForm', 'true')
    }
  }, [])

  React.useEffect(() => {
    checkFormCompletion()
  }, [inputs, checkFormCompletion])

  React.useEffect(() => {
    setIsModified(isFormChanged())
  }, [inputs, isFormChanged])

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <Flex vertical gap='middle' style={{ height: '100%', width: '100%', overflowX: 'hidden', paddingTop: '1em' }}>
        {/* File #2: Form content */}
        <Row gutter={[16, 16]} justify='start'>
          <Col xs={{ span: 24, offset: 1 }} lg={{ span: 12, offset: 6 }}>
            <Form layout='vertical' form={form} onFinish={handleSubmit} requiredMark={false}>
              <Flex justify='space-between' style={{ marginBottom: '1em', display: 'flex', alignItems: 'center' }}>
                <div>
                  <Typography.Title level={4} style={{ margin: 0 }}>
                    Carga tus contenidos
                  </Typography.Title>
                </div>

                {/*  Date selector */}
                <div>
                  <Form.Item
                    name='date'
                    rules={[{ type: 'object', required: true, message: 'Seleccionar una fecha' }]}
                    style={{ marginBottom: 0, flexGrow: 1, width: '100%' }}
                    initialValue={today}
                  >
                    <DatePicker
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        padding: '8px 16px',
                      }}
                      className='custom-placeholder'
                      placeholder='Seleccionar fecha'
                      suffixIcon={<Calendar width={14} style={{ color: '#344054' }} />}
                      cellRender={currentDate => {
                        const dayjsDate = dayjs(currentDate)
                        const isDateWithContent = isSubmittedDate(dayjsDate)
                        return <div style={isDateWithContent ? submittedDateStyle : undefined}>{dayjsDate.date()}</div>
                      }}
                    />
                  </Form.Item>
                </div>
              </Flex>

              <SortableContext items={inputs.map(input => input.id)} strategy={rectSortingStrategy}>
                {inputs.map((input, index) => (
                  <SortableCard
                    key={input.id}
                    input={input}
                    required={index < INITIAL_REQUIRED_CARDS}
                    handleInputChange={handleInputChange}
                    isSuccess={saveState.lastSavedDate === currentDate && saveState.savedCards[input.id]}
                    onDelete={handleDeleteCard}
                  />
                ))}
              </SortableContext>
              <Form.Item>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <SecondaryButton onClick={handleAddCard} style={{ width: '100%' }} icon={<Plus width={15} />}>
                    Agregar tarjeta
                  </SecondaryButton>
                  <div
                    style={{
                      position: 'fixed',
                      top: '80%',
                      right: '6%',
                      transform: 'translateY(-50%)',
                      zIndex: 1000,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {!isFormComplete || !isModified || hasDuplicateUrl ? (
                      <Tooltip
                        title='Recuerda que necesitas completar 4 tarjetas y no repetir URLs para poder enviar contenido'
                        placement='left'
                      >
                        <Button
                          htmlType='submit'
                          disabled
                          icon={<Save width={15} />}
                          style={{
                            width: '200px',
                            height: '45px',
                            fontSize: '14px',
                            fontWeight: 600,
                          }}
                        >
                          Guardar Contenido
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        htmlType='submit'
                        disabled={!isFormComplete || !isModified || hasDuplicateUrl}
                        icon={<Save width={15} />}
                        style={{
                          width: '200px',
                          height: '45px',
                          fontSize: '14px',
                          fontWeight: 600,
                        }}
                      >
                        Guardar Contenido
                      </Button>
                    )}
                  </div>
                  <div
                    style={{
                      position: 'fixed',
                      top: '90%',
                      right: '6%',
                      transform: 'translateY(-50%)',
                      zIndex: 1000,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip title='Restableces el formulario a su estado inicial' placement='left'>
                      {/* Botón Restablecer con Modal */}
                      <SecondaryButton
                        type='default'
                        icon={<RotateCcw width={15} />}
                        onClick={showResetConfirmation}
                        disabled={!isModified}
                        style={{
                          width: '200px',
                          height: '45px',
                          fontSize: '14px',
                          fontWeight: 600,
                          border: !isModified ? '2px solid transparent' : '2px solid red',
                          color: !isModified ? 'rgba(0, 0, 0, 0.25)' : 'red',
                          cursor: !isModified ? 'not-allowed' : 'pointer',
                        }}
                      >
                        Restablecer
                      </SecondaryButton>
                    </Tooltip>
                    {/* Modal de confirmación */}
                    <Modal
                      title='Restablecer'
                      open={isModalVisible}
                      onCancel={handleCancel}
                      footer={[
                        <Button key='cancel' onClick={handleCancel}>
                          Cancelar
                        </Button>,
                        <Button key='reset' type='primary' danger onClick={handleConfirmReset}>
                          Restablecer
                        </Button>,
                      ]}
                    >
                      <p>
                        ¿Estás seguro de que deseas restablecer el formulario a su estado inicial? Todos los cambios se
                        perderán.
                      </p>
                    </Modal>
                  </div>
                </div>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Flex>
      {/* Help button */}
      <FloatButton
        icon={<QuestionCircleOutlined />}
        style={{ right: 50, top: 24 }}
        onClick={() => setHelpModalOpen(true)}
      />
      {/* Modal */}
      <HelpModal open={isHelpModalOpen} onClose={() => setHelpModalOpen(false)} />
    </DndContext>
  )
}
