import React from 'react'
import { Card, Col, Row, Tag, Tooltip, Typography, Skeleton } from 'antd'
import { arc } from 'd3-shape'
import { scaleLinear } from 'd3-scale'
import { Play } from 'lucide-react'

import { ConfigType } from '../../../theme/card'
import { tokens } from '../../../theme/tokens'
import { typography } from '../../../theme/typography'
import { getMetricTooltipContent, calculateRatio } from '../../../utils/metrics-utils'
import { useDashboardContext } from '../../../context/dashboard'

const { Text } = Typography

const ARC_THICKNESS = 0.75
const OUTER_RADIUS = 1

interface SuccessfulViewsProps {
  impressions: number
  video_views: number
  config: ConfigType
}

const getCoordsOnArc = (angle: number, offset: number = 10) => [
  Math.cos(angle - Math.PI / 2) * offset,
  Math.sin(angle - Math.PI / 2) * offset,
]

const ruleOfThree = (value: number, min: number, max: number) => {
  const range = max - min
  return value / range
}

const SuccessfulViews: React.FC<SuccessfulViewsProps> = ({ impressions, video_views, config }) => {
  const { calculatePerformanceMetric, getPerformanceLabel, isLoading } = useDashboardContext()
  const { minRatio, maxRatio } = calculatePerformanceMetric

  // cálculo del ratio de video_views/impressions
  const ratio = calculateRatio(video_views, impressions)
  const percentage = (ratio * 100).toFixed(2)
  const { label, color } = getPerformanceLabel(ratio)

  // normalizo el ratio para que esté entre [0, 1] y poder usarlo en la escala del gauge
  let scaledNumber
  if (minRatio === 0 && maxRatio === 0 && ratio === 0) {
    scaledNumber = 0
  } else {
    scaledNumber = ruleOfThree(ratio, minRatio, maxRatio)
  }

  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true)

  const angle = angleScale(scaledNumber)
  const markerLocation = getCoordsOnArc(angle, 1 - (1 - ARC_THICKNESS) / 2)
  // sections of the arc
  const sections = [
    {
      color: tokens.colors.supporting.red[500],
      startAngle: -Math.PI / 2,
      endAngle: -Math.PI / 6,
    },
    {
      color: tokens.colors.supporting.yellow[500],
      startAngle: -Math.PI / 6,
      endAngle: Math.PI / 6,
    },
    {
      color: tokens.colors.supporting.green[500],
      startAngle: Math.PI / 6,
      endAngle: Math.PI / 2,
    },
  ]

  // parametric arc generator
  const arcGenerator = arc<{
    innerRadius: number
    outerRadius: number
    startAngle: number
    endAngle: number
  }>()
    .innerRadius(ARC_THICKNESS)
    .outerRadius(OUTER_RADIUS)

  return (
    <Tooltip title={getMetricTooltipContent({ metricType: 'successfulViews', value: video_views })} placement='topLeft'>
      <Card
        style={{
          ...config.size,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          padding: '7px',
        }}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <Row
          style={{
            width: '100%',
            height: config.rowHeight,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Col>
            <Play style={{ width: '14px' }} />
          </Col>
          <Col>
            {isLoading ? (
              <Skeleton.Input active={true} size='small' />
            ) : (
              <Tag
                style={{
                  width: '105px',
                  height: '20px',
                  borderRadius: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  backgroundColor: tokens.colors.primary.white,
                  fontSize: typography.label.fontSize,
                }}
              >
                {label}
                <span
                  style={{
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: color,
                  }}
                />
              </Tag>
            )}
          </Col>
        </Row>
        <Row
          style={{
            width: '100%',
            height: '55px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {isLoading ? (
            <Skeleton.Input active={true} />
          ) : (
            <Col style={{ display: 'flex', justifyContent: 'center' }}>
              <svg style={{ overflow: 'visible' }} width={`${config.gaugeSize}em`} viewBox={[-1, -1, 2, 1].join(' ')}>
                {sections.map(({ color, startAngle, endAngle }, index) => (
                  <path
                    key={index}
                    d={
                      arcGenerator({
                        innerRadius: ARC_THICKNESS,
                        outerRadius: OUTER_RADIUS,
                        startAngle: startAngle,
                        endAngle: endAngle,
                      }) || ''
                    }
                    fill={color}
                  />
                ))}
                <circle
                  cx={markerLocation[0]}
                  cy={markerLocation[1]}
                  r='0.13'
                  stroke={color}
                  strokeWidth='0.08'
                  fill='white'
                />
              </svg>

              <div
                style={{
                  position: 'absolute',
                  bottom: '-0.1em',
                  fontSize: config.fontSize.gaugeValue,
                  lineHeight: '1em',
                  fontWeight: '700',
                }}
              >
                {percentage}%
              </div>
            </Col>
          )}
        </Row>
        <Row
          style={{
            width: '100%',
            height: config.rowHeight,
            flexGrow: 1,
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Text style={{ fontSize: config.fontSize.title }}>{config.title.successfulViews}</Text>
        </Row>
      </Card>
    </Tooltip>
  )
}

export default SuccessfulViews
