import React from 'react'
import { Image, Skeleton } from 'antd'

const PREVIEW_WIDTH = 72
const PREVIEW_HEIGHT = 128

interface PosterProps {
  url: string | undefined // Puede ser el poster de Instagram o de nuestra DB
  preview: boolean
}

export const Poster: React.FC<PosterProps> = React.memo(
  ({ url, preview }) => {
    const showPlaceholder = !url || !preview

    return (
      <div
        style={{
          width: PREVIEW_WIDTH,
          height: PREVIEW_HEIGHT,
          backgroundColor: '#F8F7F7',
          borderRadius: 3,
          overflow: 'hidden',
          position: 'relative',
          marginTop: '24px',
        }}
      >
        {showPlaceholder && (
          <div
            style={{
              width: PREVIEW_WIDTH,
              height: PREVIEW_HEIGHT,
              backgroundColor: '#F8F7F7',
              borderRadius: 3,
            }}
          />
        )}
        {/* Mostrar un skeleton mientras se carga */}
        {!preview ? (
          <Skeleton.Image
            active
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        ) : (
          <Image
            src={url}
            alt='preview'
            width='100%'
            height='100%'
            style={{ objectFit: 'cover', borderRadius: 3 }}
            preview={true}
            fallback=''
          />
        )}
      </div>
    )
  },
  (prevProps, nextProps) =>
    prevProps.url === nextProps.url && prevProps.preview === nextProps.preview,
)
