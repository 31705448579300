import React from 'react'
import dayjs, { Dayjs } from 'dayjs'

import { SortState } from '../../types/sort'
import { MetricRanges, PerformanceConfig } from '../../types/performance'

import { formatDateRange } from '../config/date-range-config'
import { PERFORMANCE_STYLES } from '../constants/performance'

import { sortMetricsByConfig } from '../utils/sorting'
import { calculateRatio } from '../utils/metrics-utils'

import { useFetchAnalytics } from '../hooks/fetch-video-data'
import { PerformanceMetrics } from '../../../types/videos'

const ITEMS_PER_PAGE = 10
const DEFAULT_DATE_RANGE: [Dayjs, Dayjs] = [dayjs().subtract(1, 'week'), dayjs()]
const PERFORMANCE_THRESHOLD = 0.15

const initialPerformanceValues = {
  minRatio: 0,
  maxRatio: 0,
  midpoint: 0,
  lowThreshold: 0,
  highThreshold: 0,
}

interface DashboardProviderProps {
  children: React.ReactNode
  widgetId: string
}
interface DashboardContextType {
  activeDateRange: [Dayjs | null, Dayjs | null]
  setDateRange: (range: [Dayjs | null, Dayjs | null], showMessage?: boolean) => void
  activeDateRangeText: string

  performanceMetrics: PerformanceMetrics
  displayedMetrics: PerformanceMetrics

  sortConfig: SortState
  setSortConfig: React.Dispatch<React.SetStateAction<SortState>>

  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>

  calculateCTRmetric: MetricRanges
  calculatePerformanceMetric: MetricRanges
  getPerformanceLabel: (value: number) => PerformanceConfig
  getCTRlabel: (value: number) => PerformanceConfig

  isLoading: boolean
}

const DashboardContext = React.createContext<DashboardContextType | undefined>(undefined)

export const DashboardProvider: React.FC<DashboardProviderProps> = ({ children, widgetId }) => {
  const { fetchAnalytics } = useFetchAnalytics({ widgetId })

  const [isLoading, setIsLoading] = React.useState(false)
  const [activeDateRange, setActiveDateRange] = React.useState<[Dayjs | null, Dayjs | null]>(DEFAULT_DATE_RANGE)
  const [sortConfig, setSortConfig] = React.useState<SortState>({
    type: 'impressions',
    direction: 'desc',
  })
  const [currentPage, setCurrentPage] = React.useState(1)
  const [performanceMetrics, setPerformanceMetrics] = React.useState<PerformanceMetrics>({
    summary: {
      video_source: '',
      impressions: 0,
      cta_click: 0,
      video_views: 0,
      startDate: '',
      endDate: '',
    },
    videos: [],
  })

  const activeDateRangeText = React.useMemo(() => formatDateRange(activeDateRange), [activeDateRange])

  const getAnalytics = React.useCallback(
    async (range: [Dayjs | null, Dayjs | null]) => {
      try {
        setIsLoading(true)
        let [start, end] = range
        if (!start || !end) {
          end = dayjs()
          start = dayjs().subtract(1, 'week')
        }

        const data = await fetchAnalytics(start, end)
        if (!data.videos.length) {
          return setPerformanceMetrics({
            summary: {
              video_source: '',
              impressions: 0,
              cta_click: 0,
              video_views: 0,
              startDate: '',
              endDate: '',
            },
            videos: [],
          })
        }
        const sorted = sortMetricsByConfig(data.videos, sortConfig)

        setPerformanceMetrics({
          summary: data.summary,
          videos: sorted,
        })
      } catch (err) {
        console.error('Dashboard error:', err)
      } finally {
        setIsLoading(false)
      }
    },
    [fetchAnalytics, sortConfig],
  )

  const displayedMetrics = React.useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    const endIndex = startIndex + ITEMS_PER_PAGE
    return {
      summary: performanceMetrics.summary,
      videos: performanceMetrics.videos.slice(startIndex, endIndex),
    }
  }, [performanceMetrics, currentPage])

  const setDateRange = React.useCallback((newRange: [Dayjs | null, Dayjs | null]) => {
    setActiveDateRange(newRange)
  }, [])

  const calculateCTRmetric = React.useMemo(() => {
    if (!performanceMetrics.videos.length) {
      return initialPerformanceValues
    }

    const ratios = performanceMetrics.videos.map(video => {
      const ratio = calculateRatio(video.cta_click, video.video_views)
      return ratio
    })

    const minRatio = Math.min(...ratios)
    const maxRatio = Math.max(...ratios)

    const midpoint = (minRatio + maxRatio) / 2

    const lowThreshold = midpoint - midpoint * PERFORMANCE_THRESHOLD
    const highThreshold = midpoint + midpoint * PERFORMANCE_THRESHOLD

    return {
      minRatio,
      maxRatio,
      midpoint,
      lowThreshold,
      highThreshold,
    }
  }, [performanceMetrics])

  const calculatePerformanceMetric = React.useMemo(() => {
    if (!performanceMetrics.videos.length) {
      return initialPerformanceValues
    }

    const ratios = performanceMetrics.videos.map(video => {
      const ratio = calculateRatio(video.video_views, video.impressions)
      return ratio
    })

    const minRatio = Math.min(...ratios)
    const maxRatio = Math.max(...ratios)

    const midpoint = (minRatio + maxRatio) / 2

    const lowThreshold = midpoint - midpoint * PERFORMANCE_THRESHOLD
    const highThreshold = midpoint + midpoint * PERFORMANCE_THRESHOLD

    return {
      minRatio,
      maxRatio,
      midpoint,
      lowThreshold,
      highThreshold,
    }
  }, [performanceMetrics])

  const getPerformanceLabel = React.useCallback(
    (value: number): PerformanceConfig => {
      const { lowThreshold, highThreshold } = calculatePerformanceMetric

      const label = value <= lowThreshold ? 'low' : value >= highThreshold ? 'top' : 'mid'
      return PERFORMANCE_STYLES[label]
    },
    [calculatePerformanceMetric],
  )

  const getCTRlabel = React.useCallback(
    (value: number): PerformanceConfig => {
      const { lowThreshold, highThreshold } = calculateCTRmetric

      const label = value <= lowThreshold ? 'low' : value >= highThreshold ? 'top' : 'mid'
      return PERFORMANCE_STYLES[label]
    },
    [calculateCTRmetric],
  )

  React.useEffect(() => {
    getAnalytics(activeDateRange)
  }, [getAnalytics, activeDateRange])

  const value = {
    activeDateRange,
    setDateRange,
    activeDateRangeText,

    performanceMetrics,
    displayedMetrics,

    sortConfig,
    setSortConfig,

    currentPage,
    setCurrentPage,

    calculatePerformanceMetric,
    calculateCTRmetric,
    getPerformanceLabel,
    getCTRlabel,

    isLoading,
  }

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
}

export const useDashboardContext = () => {
  const context = React.useContext(DashboardContext)
  if (!context) {
    throw new Error('useDashboard must be used within a DashboardProvider')
  }
  return context
}
