import React from 'react'
import { useAPIContext } from '../context/api'
import { InstagramAPI } from '../../../constructs/instagram/interface'
import { ReelData } from '../../types/reel'
import sanitizeUrl from '../utils/url-sanitizer'

const VALID_IG_LINK = /https:\/\/www\.instagram.com\/reel\/\S{9,}\/?/

export const useFetchReel = () => {
  const { callEndpoint } = useAPIContext()

  const fetchReelData = React.useCallback(
    async (reelUrl: string): Promise<ReelData | null> => {
      // validación de URL
      if (!VALID_IG_LINK.test(reelUrl)) {
        return null
      }

      try {
        const sanitizedReelUrl = sanitizeUrl(reelUrl)
        const response = await callEndpoint<InstagramAPI, 'get-preview'>('instagram', '', 'POST', {
          reelUrl: sanitizedReelUrl,
        })

        return {
          title: response.title || '',
          previewUrl: response.previewUrl,
        }
      } catch (error) {
        console.error('Error fetching reel data:', error)
        return null
      }
    },
    [callEndpoint],
  )

  return { fetchReelData }
}
